<template>
  <div class="outstandingAmts container">

	<p class="h6 text-center text-primary"><b>{{location}} - {{partnerType}}s/Orders with Outstanding Payments</b></p>    
	<br>
	<p class="text-danger" align="left">{{alert}}</p>

    <!--------------------------------- Modal: Order Details -------------------------------------------->

	<CModal title="Order Details" color="info" :show.sync="orderModal" size="lg">	
		<div slot="header">
			<div class="form-group row">
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="openOrderDetailsModify()">Modify Order</CButton>
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="orderModal=false">Close</CButton>
			</div>
			<div>
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
		</div>	

		<div slot="footer">
		</div>

		<div class="form-group row">
			<label class="col-sm-6 font-weight-bold text-left">Nbr: {{order.orderNbrShort}}</label>
			<label class="col-sm-6 font-weight-bold text-right">Alternate Nbr: {{ order.altNbr }}</label>
		</div>

		<p class="font-weight-bold" align="center">Payment Details</p>
		<div class="row">
			<label class="col-sm-12 text-left">
				Total={{ order.total }}, Paid So Far={{ order.paidSoFar }}, Outstanding: {{ order.outstanding }}
			</label>
		</div>

		<div v-for="payment in order.payments" :key="payment.date">
			<div class="row">
				<label class="col-sm-12 text-left">
					{{ new Date(payment.date).toLocaleDateString() }}, 
					{{ payment.type }}, 
					{{ payment.amt }}
				</label>

				<!-- <div class="col-sm-4 text-left">{{ new Date(payment.date).toLocaleDateString() }}</div>
				<div class="col-sm-4 text-left">{{ payment.type }}</div>
				<div class="col-sm-4 text-left">{{ payment.amt }}</div> -->
			</div>
		</div>
		<br> 

		<p class="font-weight-bold" align="center">{{partnerType}}</p>
		<form>
			<div class="form-group row">
			<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
			<input disabled type="text" class="col-sm-8 form-control" v-model="order.partner.name">
			</div>

			<div class="form-group row">
			<label class="col-sm-2 col-form-label font-weight-bold text-right">Phone</label>
			<input disabled type="text" class="col-sm-3 form-control" v-model="order.partner.phone">

			<label class="col-sm-2 col-form-label font-weight-bold text-right">State</label>
			<input disabled type="text" class="col-sm-3 form-control" v-model="order.partner.state">
			</div>
		</form>
		<br>

		<p class="font-weight-bold" align="center">Order Details</p>
		<vue-good-table ref="orderTable" :columns="colItems" :rows="rowItems" 
			styleClass="vgt-table bordered condensed"
			:lineNumbers="true"
			:sort-options="{enabled: false}"
		> 
		</vue-good-table>

	</CModal>


 <!--------------------------------- Main Page: Customers/Orders with Outstanding Payments -------------------------------------------->

	<div>
		<vue-good-table
			ref="outstandingPaymentsTable"
			:columns="colOrders"
			:rows="rowOrders"
			:line-numbers="true"
			:pagination-options="pagination_options" 
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table bordered condensed"
			@on-cell-click="orderSelected" 
			:group-options="{
					enabled: true,
					collapsable: true 
				}"
			>		
			<div slot="table-actions">
				<CButton color="dark" class="mr-2" v-on:click.prevent="$refs.outstandingPaymentsTable.expandAll()">Expand All</CButton>
				<CButton color="dark" class="mr-2" v-on:click.prevent="$refs.outstandingPaymentsTable.collapseAll()">Collapse All</CButton>
				<CButton color="dark" class="mr-2" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field == 'orderNbrShort'">
					<span><a href="#">{{ props.row.orderNbrShort }}</a></span> 
				</span>
			</template>
			<template slot="table-column" slot-scope="props">
				<span v-if="props.column.label =='Name' || props.column.label =='Phone'">
					{{partnerType}}  {{props.column.label}}
				</span>
				<span v-else>
					{{props.column.label}}
				</span>
			</template>

	  	</vue-good-table>


		<!--------------------------------- Footer: Summary Outstanding Amount -------------------------------------------->

		<br>
		<div class="card">
			<div class="card-body">
				<h5 class="card-title text-center">Summary</h5>
				<hr>
				<form>
					<div class="form-group row">
						<label class="col-sm-3 col-form-label font-weight-bold text-right">Total Amount</label>
						<label class="col-sm-2 col-form-label text-left">{{summary['total'].toFixed(2)}}</label>

						<label class="col-sm-2 col-form-label font-weight-bold text-right">Cash payments</label>
						<label class="col-sm-2 col-form-label text-left">{{summary['cash'].toFixed(2)}}</label>
					</div>
					<div class="form-group row">
						<label class="col-sm-3 col-form-label font-weight-bold text-right">Outstanding Amount</label>
						<label class="col-sm-2 col-form-label text-left">{{summary['outstanding'].toFixed(2)}}</label>

						<label class="col-sm-2 col-form-label font-weight-bold text-right">Bank payments</label>
						<label class="col-sm-2 col-form-label text-left">{{summary['bank'].toFixed(2)}}</label>
					</div>
					<div class="form-group row">
						<label class="col-sm-3 col-form-label font-weight-bold text-right"></label>
						<label class="col-sm-2 col-form-label text-left"></label>

						<label class="col-sm-2 col-form-label font-weight-bold text-right">Other Payments</label>
						<label class="col-sm-2 col-form-label text-left">{{summary['other'].toFixed(2)}}</label>
					</div>
				</form>
			</div>
		</div>

	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'orders',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			alert: '',

			location: '',
			orderType: 'Sale',
			partnerType: 'Customer',

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
			
			loading: false,

			colOrders: [
				{	
					label: 'Name',
					field: 'partnerName',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Order Number',
					field: 'orderNbrShort',
					html: true,
					filterOptions: {'enabled': true}
				},
				{
					label: 'Alternate Order Number',
					field: 'altNbr',
					filterOptions: {'enabled': true}
				},
				{
					label: 'Order Total',
					field: 'total',
					type: 'number',
					sortable: true,
					headerField: this.sumTotal,
				},
				{
					label: 'Outstanding',
					field: 'outstanding',
					type: 'number',
					sortable: true,
					headerField: this.sumOutstanding,
				},
				{
					label: 'Create Date',
					field: 'create_date',
					sortable: true,
					formatFn: this.formatDate,
				},
				{
					label: 'Invoice Date',
					field: 'invoice_date',
					sortable: true,
					formatFn: this.formatDate,
				},
				{
					label: 'Payment Date',
					field: 'payment_date',
					sortable: true,
					formatFn: this.formatDate,
				},
				{
					label: 'Delivery Date',
					field: 'delivery_date',
					sortable: true,
					formatFn: this.formatDate,
				},
			],

        	rowOrders: [],

			reportName: "Customers-Orders with Outstanding Payments" ,
			reportData: "",
			summary: {'total': 0, 'outstanding': 0, 'cash': 0, 'bank':0, 'other': 0},

			orderModal: false,
			order: {'nbr': '', 'type': 'Sale', 'location': this.location,
                        'dateStr': '', 'status': 0, 'total': 0, 'allGstOrder': 'Yes', 'note': '',
                        'items': [], 
                        'partner':{'name': '', 'phone': '', 'email': '', 'address': ''},
                        'payInstructions': '', 'paid': 'No', 'paymentDateStr': null, 'paidAmt': 0,
                        'delivered': 'Yes', 'deliveryDateStr': this.currentDate, 
						'cancelled': 'No', 'cancelledDateStr': null
                       },

			curRowSelected: null,
			selectedRows: [],

			colItems: [
				{
					label: 'Item1',
					field: 'item1Name',
					thClass: 'text-center',
				},
				{
					label: 'Item2',
					field: 'name',
					thClass: 'text-center',
				},
				{
					label: 'Unit Price',
					field: 'price',
					thClass: 'text-center',
					type: 'number',
				},
				{
					label: 'Unit Tax',
					field: 'tax',
					thClass: 'text-center',
					tdClass: 'text-right',
				},
				{
					label: 'Unit Discount',
					field: 'discount',
					thClass: 'text-center',
					type: 'number',
				},
				{
					label: 'Unit Sale Price',
					field: 'salePrice',
					thClass: 'text-center',
					type: 'number',
				},            
				{
					label: 'Quantity',
					field: 'qty',
					thClass: 'text-center',
					type: 'number',
				},
				{
					label: 'Total',
					field: 'total',
					thClass: 'text-center',
					type: 'number',
				},
			],

			rowItems: [],

			orderModal: false,
			
		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
	    this.orderType = this.$route.params['orderType']
		this.location = this.$route.params['location']
		if (this.orderType == 'Sale')
			this.partnerType = 'Customer'
		else 
			this.partnerType = 'Supplier'

		this.serverCall('getOutstandingPayments')
	},

	methods: {
		sumTotal: function (rowObj) {
			let sum = 0;
			for (let i = 0; i < rowObj.children.length; i++) {
				sum += rowObj.children[i].total;
			}
			return sum;
		},

		sumOutstanding: function (rowObj) {
			let sum = 0;
			for (let i = 0; i < rowObj.children.length; i++) {
				sum += rowObj.children[i].outstanding;
			}
			return sum;
		},

		// Current Selected Rows
		selectionChanged(params) {
            this.selectedRows = params.selectedRows;
        },

		// User clicks on 'nbrShort'	
		orderSelected(params){	
			// Current selected Row
			if (this.curRowSelected != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowSelected.originalIndex!=params.row.originalIndex)
					this.curRowSelected.vgtSelected = false
			}
			// New selected row
			this.curRowSelected = params.row

			if (params.column.field=="orderNbrShort"){
				this.showOrderDetailsView()
			}
		},

		// Show Order Details Modal
		showOrderDetailsView(){
            var self = this

            // Check if User is selected
			if (self.curRowSelected==null){
				self.alert = "Order not selected."
				return
			}

			// Initialize order object
			self.order = self.curRowSelected
			self.rowItems = self.order.items

			// Show modal with order details
			self.orderModal = true

		},

		// Open Order Details Modification
		openOrderDetailsModify(){
            var self = this
			self.alert = null

			self.$router.push({ name: 'Order Entry', 
								params: { 'location': self.location,
										  'orderType': self.orderType,
										   'order': self.order
										} 
							 })
		},


        serverCall(name, status) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getOutstandingPayments":
								self.getOutstandingPayments(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Customers/Orders with Outstanding Payments
        getOutstandingPayments(authToken) {
            var self = this

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Outstanding Payments..."

			var postJson = {
				'orderType': this.orderType,
				'location': this.location
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetOutstandingPayments/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getItem1ReportData - error:" + response.data.err)
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
					else {
						self.rowOrders = response.data.dataList
						self.reportData = response.data.reportData
						self.summary = response.data.summary
						
						// Reset loading message
						self.loading = false
						self.loadingMsg = null
					}
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


		downloadReport() {
			if (this.reportData==""){
				this.$alert("No data to Report.", '', 'error', { confirmButtonColor: '#161c2d' })
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportName + '.xlsx');
		},

		base64toBlob(base64Data, contentType) {
			contentType = contentType || '';
			let sliceSize = 1024;
			let byteCharacters = atob(base64Data);
			let bytesLength = byteCharacters.length;
			let slicesCount = Math.ceil(bytesLength / sliceSize);
			let byteArrays = new Array(slicesCount);
			for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
				let begin = sliceIndex * sliceSize;
				let end = Math.min(begin + sliceSize, bytesLength);

				let bytes = new Array(end - begin);
				for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
					bytes[i] = byteCharacters[offset].charCodeAt(0);
				}
				byteArrays[sliceIndex] = new Uint8Array(bytes);
			}
			return new Blob(byteArrays, { type: contentType });
		},


	},

}
</script>

<style>
.active {
  color : green;
}
.custom-th-class {
  text-align: center;	
}
</style>

